export const environment = {
  production: false,
  enterprise: false,
  light: false,
  proxy: false,
  BACKEND_DOMAIN: 'https://api.ops.ol-hub.com/backend',
  BACKOFFCIE_URL: 'https://api.ops.ol-hub.com/backoffice',
  AUDIT_SERVICE_URL: 'https://api.ops.ol-hub.com/audit',
  DT_ENGINE_URL: 'https://api.ops.ol-hub.com/dt-engine',
  AUTH_SERVICE_URL: 'https://api.ops.ol-hub.com/auth-service',
  SOLUTION_CENTER_URL: 'https://api.ops.ol-hub.com/solution-center',
  RUNTIME_SERVICE_URL: 'https://api.ops.ol-hub.com/runtime',
  FLAGS_ROUTER_SERVICE_URL: 'https://api.ops.ol-hub.com/flags-router',
  NOTIFICATION_SERVICE_URL: 'https://api.ops.ol-hub.com/notifications',
  AUTH0_SPA_CLIENT_ID: 'EVbM7s5lra5hLKNmLpgWThmZBWFdpKNM',
  AUTH0_DOMAIN: 'ol-hub-ops.eu.auth0.com',
  AUTH0_CONNECTION: 'ol-auth0-db',
  MIXPANEL_TOKEN: '__MIXPANEL_TOKEN',
  HOTJAR_SITE_ID: '__HOTJAR_SITE_ID',
  // NGXS plugins
  plugins: [],
  // mock auth for light hub
  MOCK_IAM_URL: '',
  MONITORING_URL: 'https://grafana.${region}.ops.ol-hub.com'
};
